// Generated by Framer (89417f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {Sr9v6GFwa: {pressed: true}};

const cycleOrder = ["Sr9v6GFwa"];

const variantClassNames = {Sr9v6GFwa: "framer-v-1co5kme"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, kOVVhJoqk: image ?? props.kOVVhJoqk ?? {src: "https://framerusercontent.com/images/yLihLbaZY2rxtVbtMyp3M6hnBQ.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/yLihLbaZY2rxtVbtMyp3M6hnBQ.png?scale-down-to=512 389w, https://framerusercontent.com/images/yLihLbaZY2rxtVbtMyp3M6hnBQ.png?scale-down-to=1024 779w, https://framerusercontent.com/images/yLihLbaZY2rxtVbtMyp3M6hnBQ.png?scale-down-to=2048 1559w, https://framerusercontent.com/images/yLihLbaZY2rxtVbtMyp3M6hnBQ.png 1920w"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, kOVVhJoqk, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Sr9v6GFwa", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-7CuNy", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link {...addPropertyOverrides({"Sr9v6GFwa-pressed": {href: "https://ppauto.sk/"}}, baseVariant, gestureVariant)}><Image {...restProps} background={{alt: "", fit: "fit", intrinsicHeight: 2521, intrinsicWidth: 1920, pixelHeight: 2521, pixelWidth: 1920, sizes: "min(313px, 100vw)", ...toResponsiveImage(kOVVhJoqk)}} className={`${cx("framer-1co5kme", className)} framer-q4sw6h`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Sr9v6GFwa"} ref={ref} style={{...style}} {...addPropertyOverrides({"Sr9v6GFwa-pressed": {"data-framer-name": undefined, as: "a"}}, baseVariant, gestureVariant)}/></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-7CuNy [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7CuNy .framer-q4sw6h { display: block; }", ".framer-7CuNy .framer-1co5kme { height: 411px; overflow: hidden; position: relative; width: 313px; }", ".framer-7CuNy .framer-v-1co5kme .framer-1co5kme { cursor: pointer; }", ".framer-7CuNy.framer-v-1co5kme.pressed .framer-1co5kme { aspect-ratio: 0.761602538675129 / 1; height: var(--framer-aspect-ratio-supported, 411px); text-decoration: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 411
 * @framerIntrinsicWidth 313
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"nDNJ7RIVH":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"kOVVhJoqk":"image"}
 * @framerImmutableVariables false
 */
const FrameryTkCdQUUW: React.ComponentType<Props> = withCSS(Component, css, "framer-7CuNy") as typeof Component;
export default FrameryTkCdQUUW;

FrameryTkCdQUUW.displayName = "pp auto c";

FrameryTkCdQUUW.defaultProps = {height: 411, width: 313};

addPropertyControls(FrameryTkCdQUUW, {kOVVhJoqk: {__defaultAssetReference: "data:framer/asset-reference,yLihLbaZY2rxtVbtMyp3M6hnBQ.png?originalFilename=DOMOV.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FrameryTkCdQUUW, [])